import React from 'react'
import {Helmet} from 'react-helmet'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Thanks() {
  const {t} = useTranslation()
  return(
    <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{t('Dexter is a fun, interactive memory program powered by AI. It was designed by memory coaching experts with a proven track record of helping tens of thousands of people train their memory, technical math skills, processing and reading speeds.')}</title>
      <link rel="canonical" href="https://dexterprogram.com/thanks" />
    </Helmet>
      <div className="h-screen bg-gradient-to-r from-teal-400 to-blue-500 overflow-x-hidden">
        <div className="h-full container mx-auto flex flex-col md:flex-row items-center my-0">
          <div className="flex flex-col mx-auto justify-center items-start p-0 md:px-6 md:py-6 text-center">
             <div className={`absolute top-0 mt-8 speech-bubble-ds`}>
               <p><strong>{t('Thank you for your interest in Dexter!')}</strong></p>
               <div className="speech-bubble-ds__arrow"></div>
             </div>
            <img src="/dexter-thanks.png" alt="" />
          </div>
        </div>
      </div>
  </div>
  )
}
